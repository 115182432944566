@import './scroll.scss';

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-12 {
  padding: 12px;
}

.text-capitalize {
  text-transform: capitalize;
}

.b-0 {
  border: 0;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.block {
  display: block;
}

.fs-14 {
  font-size: 14px;
}

.mt-6 {
  margin-top: 6px;
}

.small {
  font-size: 80% !important;
}

.flex {
  display: flex;
}

.flex-auto {
  flex: auto !important;
}

.justify-content-center {
  justify-content: center;
}

.text-theme,
.color-theme {
  color: #7367F0;
  text-decoration: none;
}

.text-dim {
  color: rgba(0,0,0,0.15);
}

.text-white {
  color: #fff;
}

.text-black {
  color: #202020;
}

.text-danger {
  color: #EA5455;
}

.color-dark {
  color: #4B4B4B;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ml-auto {
  margin-left: auto;
}

.mt-100 {
  margin-top: 100px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-3 {
  margin-right: 3px;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.w-100 {
  width: 100%;
}

.img-fluid {
  max-width: 100%;
}

.text-underline {
  text-decoration: underline;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fs-13 {
  font-size: 13px;
}

.color-gray {
  color: #84818A !important;
}

.d-none {
  display: none;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.order-1 {
  order: 1;
}

@media (min-width: 767px) {
  .d-md-block {
    display: block;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-none {
    display: none;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .order-md-0 {
    order: 0;
  }
}
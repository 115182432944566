@import "./scss/helper";
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
}

p {
  &.large {
    font-size: 18px;
  }
}

.ant-btn {
  height: auto;
  border-radius: 6px;
  font-size: 15px;
  &.ant-btn-lg {
    padding: 10.4px 15px;
    border-radius: 6px;
    height: auto;
    font-size: 17px;
    font-weight: 500;
  }
}

.ant-btn-gray {
  background: #f8f9f9;
  border-color: #f8f9f9;
}
.ant-btn-primary {
  background-color: #7367f0;
  color: #fff;
  border-color: #7367f0;
  &:not(:disabled):hover {
    background-color: #7367f0;
    color: #fff;
    border-color: #7367f0;
  }
}

.ant-btn-primary-light {
  background-color: rgba(115, 103, 240, 0.16);
  color: #7367f0;
  border-color: rgba(115, 103, 240, 0.16);
  &:not(:disabled):hover {
    background-color: #7367f0;
    color: #fff;
    border-color: #7367f0;
  }
}

.authlayout {
  background: #7367f0;
  display: flex;
  gap: 65px;
  padding: 44px 48px;
  min-height: 100vh;
  box-sizing: border-box;

  .authlayout-form {
    flex: 0 0 50%;

    .authForm-card {
      padding: 50px;
      background: #fff;
      border-radius: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 56px;
        margin: 0 0 24px;
        font-weight: 600;
        color: #202020;
      }

      form {
        margin-top: 45px;
        margin-bottom: 16px;
        .ant-form-item {
          margin: 0 0 32px;
        }
        .ant-form-item-extra {
          margin-top: 16px;
          color: #4b465c;
        }
        .ant-form-item-label {
          padding: 0 0 16px;
          label {
            color: #4b4b4b;
          }
        }
        .ant-input-affix-wrapper {
          line-height: 2.571429;
        }
        .ant-checkbox + span {
          color: #84818a;
        }
      }
    }
  }

  .authlayout-image {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.otherMethods {
  margin: 32px 0 48px;
}
.cut-line {
  position: relative;
  p {
    padding: 0 15px;
    background: #fff;
    position: relative;
    z-index: 1;
    display: inline-block;
  }
  hr {
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    height: 1px;
    background: #a8aaae;
    margin: 0;
  }
}

.signin-types {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 46px;
  .ant-btn {
    flex: 1 0 auto;
    padding: 10px 10px;
    height: auto;
  }
}

.user-loggedin {
  background: #f8f7fa;
  min-height: 100vh;
  .main-section {
    display: flex;
    padding: 32px;
    .sideContent {
      flex: 0 0 22%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
    main {
      flex: 0 0 56%;
      padding: 0 30px;
    }
  }
}

header {
  background: #fff;
  padding: 16px 30px;
  border-bottom: 1px solid #dfdfe3;
}

.card-layout {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.653);
  -moz-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.3);
  .card-header {
    border-bottom: 1px solid rgb(168 170 174 / 16%);
    padding: 14px 20px;
    h3 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
      color: #4b4b4b;
    }
  }

  .card-body {
    padding: 10px 0;
    ul.points-section {
      list-style: none;
      padding: 0;
      margin: 0;
      li.bullet-point {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgb(168 170 174 / 16%);
        .avatar {
          width: 48px;
          flex: 0 0 48px;
          height: 48px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #7367f0;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
        }
        .con-status {
          width: 48px;
          height: 48px;
          border-radius: 100%;
          background: rgba(168, 170, 174, 0.6);
          border: 3px solid #a8aaae;
          position: relative;
          .ant-image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
            margin-bottom: -23px;
          }
          .status {
            display: inline-block;
            position: absolute;
            right: -2px;
            bottom: -3px;
            border: 2px solid #fff;
            background: #a8aaae;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            &.online {
              background: #28c76f;
            }
            &.away {
              background: #28c76f;
            }
            &.out {
              background: #ff9f43;
            }
            &.offline {
              background: #ea5455;
            }
          }
          &.online {
            border-color: #28c76f;
            background: #86dfae;
          }
          &.away {
            border-color: #28c76f;
            background: #cecfd1;
          }
          &.out {
            border-color: #7367f0;
            background: #b0a9f7;
          }
          &.offline {
            border-color: #ea5455;
            background: #f39fa0;
          }
        }
        .avatar-desc {
          padding: 0 12px;
          flex: auto;
          display: flex;
          flex-direction: column;
          gap: 4px;

          h4 {
            font-size: 13px;
            color: #4b4b4b;
          }
          p {
            font-size: 13px;
            color: #4b465c;
          }
        }
      }
    }
  }

  .card-footer {
    border-bottom: 1px solid rgb(168 170 174 / 16%);
    padding: 0 20px 10px;
    .ant-btn-lg {
      padding: 7.25px 15px;
      font-size: 15px;
    }
  }

  &.connections-card {
    padding: 16px;
    .card-body {
      padding: 0;
      ul.points-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li.bullet-point {
          border: 1px solid rgba(168, 170, 174, 0.16);
          border-radius: 8px;
          padding: 16px;
          .avatar-button {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
          }
        }
      }
    }
  }
}

.no-shadow-cards {
  .card-layout {
    box-shadow: none !important;
  }
}

.pagination-btns {
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    .ant-pagination-item {
      margin: 0;
      overflow: hidden;
      a {
        background: #f1f1f2;
      }
      &.ant-pagination-item-active {
        a {
          background: #7367f0;
          color: #fff;
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0;
      .ant-pagination-item-link {
        background: #f1f1f2;
      }
    }
  }
}

.search-connection {
  background: #f8f8f9;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .connection-heading h3 {
    color: #4b4b4b;
    margin: 0;
    font-size: 18px;
    .icon {
      width: 26px;
      height: 26px;
      border-radius: 6px;
      background: #fff;
      padding: 5px 6px;
      margin-right: 10px;
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    gap: 10px;
    .ant-input-wrapper {
      border: 1px solid #7367f0;
      border-radius: 6px;
      .ant-input {
        height: 40px;
        width: 250px;
        border: none;
        padding-left: 0;
        &:hover {
          box-shadow: none;
        }
      }
      .ant-input-group-addon {
        background: #fff;
        border: none;
        color: #4b465c;
      }
    }
    .ant-btn {
      padding: 7.25px 10px;
    }
  }
}

.resource-section {
  border-radius: 8px;
  border: 1px solid #e8e6fc;
  overflow: hidden;
  .resource-section-head {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e8e6fc;
    h3 {
      margin: 0;
      color: #171725;
    }
  }
  .resource-section-body {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .resource-section-box {
      display: flex;
      flex-direction: column;
      .resource-section-content {
        padding: 16px 0;
        p {
          font-size: 15px;
          line-height: 22px;
          color: #4b4b4b;
        }
        .resource-section-avatar {
          display: flex;
          align-items: center;
          gap: 16px;
          margin: 0 0 10px;
          .ant-image {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .avatar-content {
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: #4b4b4b;
            h5 {
              margin: 0;
              font-size: 18px;
              font-weight: 700;
            }
            p {
              font-size: 12px;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
        + .resource-section-content {
          border-top: 1px solid #e8e6fc;
        }
      }
    }
  }
}

.profile-section {
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.653);
  -moz-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.3);

  .profile-section-intro {
    text-align: center;
    position: relative;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    .intro-bg {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background: url("../src/images/introBg.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      height: 120px;
    }
    .profile-img {
      position: relative;
      .ant-image {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 5px solid #fff;
        overflow: hidden;
        background: url("../src/images/userBg.png") no-repeat;
        background-size: cover;
        background-position: center;
      }
      .status {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background: #eee;
        display: inline-block;
        position: absolute;
        right: 27px;
        bottom: 20px;
        border: 4px solid #fff;
        &.online {
          background: #28c76f;
        }
      }
    }
    .into-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      h3 {
        margin: 0;
        font-size: 22px;
      }
      // .ant-rate {
      //   .anticon {
      //     color: #FF9F43;
      //   }
      // }
    }
  }

  .profile-section-completness {
    background: #e8e6fc;
    padding: 10px 20px;
    margin: 20px 0 16px;
    p {
      font-size: 14px;
      font-weight: 600;
    }
    .ant-progress {
      margin: 0;
      .ant-progress-inner {
        background: rgba(255, 255, 255, 0.35);
        .ant-progress-bg {
          background: #7367f0;
        }
      }
    }
  }

  .profile-section-points {
    padding: 0 20px 10px;
    .point-l {
      font-size: 15px;
      display: flex;
      align-items: center;
      color: #4b465c;
      padding: 12px 0;
      border-bottom: 1px solid #f8f9f9;
      span {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #fff;
        width: 22px;
        height: 22px;
        background: #dbdade;
        border-radius: 100%;
      }
      &.active {
        color: #7367f0;
        font-weight: 700;
        span {
          background: #7367f0;
        }
      }
    }
  }
}

.header-menu {
  .ant-menu {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-menu-item {
      padding: 0 22px;
      line-height: normal;
      a {
        display: flex;
        flex-direction: column;
        gap: 6px;
        color: #4b465c;
        font-weight: 600;
        .icon {
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
        &:hover {
          color: #7367f0;
          .icon {
            svg path {
              fill: #7367f0;
            }
          }
        }
      }
      &.ant-menu-item-selected {
        a {
          color: #7367f0;
          .icon {
            svg path {
              fill: #7367f0;
            }
          }
        }
      }
      &:after {
        content: none;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.other-menu-item {
  .ant-menu-title-content {
    display: flex;
    align-items: center;
    gap: 20px;
    .ant-btn {
      line-height: normal;
      border: 0;
      position: relative;
      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: 12px;
        height: 12px;
        font-size: 6px;
        font-weight: 500;
        background: #db4437;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        line-height: 1;
      }
    }
  }
}

.header-searchbar {
  border: 1px solid #7367f0;
  border-radius: 8px;
  .ant-input {
    border-left: 0;
    padding-left: 0;
    border-right: 0;
    border-radius: 0;
    width: 300px;
    border: none;
    &:focus,
    &:hover {
      box-shadow: none;
      border: none;
    }
    max-width: 99%;
  }
  .ant-input-group .ant-input-group-addon {
    background: #fff;
    border-right: 0;
    color: #4b465c;
    border: none;
  }
  .ant-select {
    .ant-select-selector {
      border: none;
      border-left: 1px solid #7367f0;
      .ant-select-selection-item {
        color: #4b465c;
      }
    }
  }
}

.dropdown-menu {
  padding: 8px;
  min-width: 240px;
  &.ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .ant-dropdown-menu-item {
      a {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: #4b4b4b !important;
        gap: 8px;
      }
      &:hover {
        background: #e8e6fc;
        a {
          color: #7367f0 !important;
        }
      }
    }
  }
}
